
const DEFAULT_OPTIONS = {
    interval: 5000,
    orientation: 'vertical',
};

export default class Ticker {
    constructor(container, options) {
        this.container = container;
        this.options = Object.assign({}, DEFAULT_OPTIONS, options);
        if (!['vertical', 'horizontal'].includes(this.options.orientation)) {
            throw new Error(`Invalid orientation value: '${this.options.orientation}'`);
        }
        this.classPrefix = `${this.options.orientation}-ticker-item`;
        this.items = new Array(...container.querySelectorAll('.' + this.classPrefix));
        if (this.options.maxItems) {
            this.active = this.items.slice(0, this.options.maxItems);
            this.inactive = this.items.slice(this.options.maxItems);
        } else {
            let isHidden, isObscured;
            if (this.options.orientation === 'vertical') {
                const height      = this.container.getBoundingClientRect().height;
                isHidden    = (el) => el.getBoundingClientRect().top >= height;
                isObscured  = (el) => el.getBoundingClientRect().bottom > height;
            } else {
                const width      = this.container.getBoundingClientRect().width;
                isHidden    = (el) => el.getBoundingClientRect().left >= width;
                isObscured  = (el) => el.getBoundingClientRect().right > width;
            }
            const firstHidden = this.items.findIndex(isHidden);
            if (firstHidden === -1) {
                this.active = this.items.slice(0);
                this.inactive = [];
                if (this.active.length && isObscured(this.active[this.active.length - 1])) {
                    this.inactive = this.active.map(el => el.cloneNode(true));
                    this.inactive.forEach(el => this.container.appendChild(el));
                }
            } else {
                this.active = this.items.slice(0, firstHidden);
                this.inactive = this.items.slice(firstHidden);
                if (!this.inactive.length) {
                    this.inactive.push(this.active[0].cloneNode(true));
                }
            }
        }
        this.timerId = null;

        for (const el of this.inactive) {
            el.classList.add(`${this.classPrefix}-inactive`);
        }

        this.onTickBegin = this.options.onTickBegin;

        container.addEventListener('mouseenter', () => this.stopTicking());
        container.addEventListener('mouseleave', () => this.startTicking());

        document.addEventListener('visibilitychange', () => {
            if (document.hidden) {
                this.stopTicking();
            } else {
                this.startTicking();
            }
        });

        this._setupIntersectionObserver();
    }

    startTicking() {
        if (!this.timerId) {
            this.timerId = setInterval(() => this.tick(), this.options.interval);
        }
    }

    stopTicking() {
        if (this.timerId) {
            clearInterval(this.timerId);
            this.timerId = null;
        }
    }

    reset(el) {
        el.classList.remove(`${this.classPrefix}-animate`);
        if (el.classList.contains(`${this.classPrefix}-outgoing`)) {
            el.classList.remove(`${this.classPrefix}-outgoing`);
            el.classList.add(`${this.classPrefix}-inactive`);
            this.container.removeChild(el);
            this.container.appendChild(el); // Move item to end of container
        }
    }

    tick() {
        if (this.onTickBegin) {
            this.onTickBegin(this);
        }

        this.items.forEach(el => el._transitionCb && el._transitionCb());

        // FLIP animation: https://aerotwist.com/blog/flip-your-animations/

        /// First
        // Record start positions
        const incoming = this.inactive.shift();
        if (!incoming) {
            return;
        }
        this.active.push(incoming);
        incoming.classList.remove(`${this.classPrefix}-inactive`);

        const outgoing = this.active.shift();
        this.inactive.push(outgoing);

        const animated = this.active.slice(0);
        animated.unshift(outgoing);

        const start = animated.map(el => el.getBoundingClientRect());

        /// Last
        // Go to end state and record positions
        outgoing.classList.add(`${this.classPrefix}-outgoing`);

        const end = animated.map(el => el.getBoundingClientRect());

        /// Invert
        // Move elements back to start position
        for (let i = 0; i < animated.length; i++) {
            if (this.options.orientation === 'vertical') {
                const invert = start[i].top - end[i].top;
                animated[i].style.transform = `translateY(${invert}px)`;
                animated[i].style.transitionDuration = '0s';
            } else {
                const invert = start[i].left - end[i].left;
                animated[i].style.transform = `translateX(${invert}px)`;
                animated[i].style.transitionDuration = '0s';
            }
        }

        // Force reflow to put everything into position
        const _unused = document.body.offsetHeight;

        /// Play
        // Animate back to end state
        animated.forEach(el => {
            el.classList.add(`${this.classPrefix}-animate`);
            el.style.transform = el.style.transitionDuration = '';
        });

        // Cleanup when transition ends
        animated.forEach(el => {
            const cb = el._transitionCb = () => {
                el.removeEventListener('transitionend', cb);
                el._transitionCb = null;
                this.reset(el);
            };
            el.addEventListener('transitionend', cb);
        });
    }

    _setupIntersectionObserver() {
        if (!('IntersectionObserver' in window)) {
            this.startTicking();
            return;
        }

        const observerOptions = {
            root: null, // null = viewport
            rootMargin: '0px',
        };

        const observer = new IntersectionObserver((entries) => {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    this.startTicking();
                } else {
                    this.stopTicking();
                }
            }
        }, observerOptions);

        observer.observe(this.container);
    }
}
